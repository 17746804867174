html,
body,
root {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: #373734;
}

.scorpiongame-color-1 {
  color: #ec5247;
}

.scorpiongame-color-1-bg {
  background-color: #ec5247;
}

.scorpiongame-color-2 {
  color: #ffe229
}

.scorpiongame-color-2-bg {
  background-color: #ffe229;
}

.scorpiongame-color-3 {
  color: #373734;
}

.scorpiongame-color-3-bg {
  background-color: #373734;
}

.scorpiongame-color-4 {
  color: #fff4c6;
}

.scorpiongame-color-4-bg {
  background-color: #fff4c6;
}

.scorpiongame-color-5 {
  color: #2b2b28;
}

.scorpiongame-color-5-bg {
  background-color: #2b2b28;
}